import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { message, Upload } from 'antd';

import { navigator, history } from '@common/navigation';

import { tokenHeaderKEY } from '@constants/';

import { store } from '@resources/store';
import { dateFormatter, addQueryToURL, bulkFileUpdate } from '@utils';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  priceOptimizationProductPrice,
  baseURLSet,
} from '@constants/serviceUrls';
import { setActionElements, getAPILanguage } from '@common';
import {
  getPriceListCode,
  saveCurrentProductPrice,
  saveProductPrice,
  getBulkProductPriceStatus,
  deleteProductPrice,
} from '@resources/actions/priceOptimization';
import {
  deleteBulkProductPrices,
  addOrDeletePricesBulkStatus,
} from '@resources/actions/bulkUpdates';

import Block from '@components/Block';
import Button from '@components/Button';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import DatatableWithFilter from '@components/DatatableWithFilter';
import Modal from '@components/Modal';

import '../style.scss';

import FileSaver from 'file-saver';
import axios from 'axios';

function OptimizationProductPrice({
  saveProductPrice,
  deleteProductPrice,
  saveCurrentProductPrice,
  getPriceListCode,
  getBulkProductPriceStatus,
  pricelist,
  addOrDeletePricesBulkStatus,
  deleteBulkProductPrices,
}) {
  const dataTableRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const { id, code } = useParams();

  const [state, setState] = useState({
    loading: false,
    excelToggle: false,
    isDownloading: [false, false],
    selectedRow: null,
    productPriceUpdateDisabled: false,
    productPriceUpdateStart: undefined,
  });

  const deleteSelectedPrice = async (_, filter) => {
    if (!confirmModalVisible) {
      const queryParams = {
        price_list: id,
        id__in: selectedRowKeys.join(','),
      };

      Modal.confirm({
        title: T('delete.count.price.title'),
        content: T('delete.count.price.info').format(selectedRowKeys.length),
        okText: T('yes'),
        cancelText: T('no'),
        icon: null,
        onOk: () => {
          setConfirmModalVisible(false);
          bulkFileUpdate(
            {
              bulkAction: deleteBulkProductPrices,
              cacheKeyAction: addOrDeletePricesBulkStatus,
              bulkActionArgs: [{ queryParams }],
              cacheKeyActionName: 'addOrDeletePricesBulkStatus',
              cacheKeyActionArgs: [],
            },
            () => {
              dataTableRef?.current?.reload();
            }
          );
        },
      });
    }
  };

  const deleteAllPrice = async (_, filter) => {
    if (!confirmModalVisible) {
      Modal.confirm({
        title: T('delete.all.price.title'),
        content: T('delete.all.price.info'),
        okText: T('yes'),
        cancelText: T('no'),
        icon: null,
        onOk: () => {
          setConfirmModalVisible(false);
          bulkFileUpdate(
            {
              bulkAction: deleteBulkProductPrices,
              cacheKeyAction: addOrDeletePricesBulkStatus,
              bulkActionArgs: [
                {
                  queryParams: { price_list: id },
                },
              ],
              cacheKeyActionName: 'addOrDeletePricesBulkStatus',
              cacheKeyActionArgs: [],
            },
            () => {
              dataTableRef?.current?.reload();
            }
          );
        },
      });
    }
  };

  const rowSelection = {
    onRowSelection: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const actionButtons = [
    {
      id: 1,
      label: T('delete.selected.price'),
      selectionRequired: true,
      customAction: deleteSelectedPrice,
    },
    {
      id: 2,
      label: T('delete.all'),
      selectionRequired: false,
      customAction: deleteAllPrice,
    },
  ];

  const actionElement = (
    <Button
      type="secondary"
      icon="icon-arti"
      onClick={() => {
        setState((prevState) => ({
          ...prevState,
          excelToggle: !prevState.excelToggle,
        }));
      }}
    >
      {T('update.price.list')}
    </Button>
  );

  useEffect(() => {
    getPriceListCode(id);
    id === undefined &&
      onNavigate('/price/price-list-optimization/price-list/');

    setActionElements([actionElement]);
    return () => {
      setActionElements([]);
    };
  }, [id, getBulkProductPriceStatus]);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'sku__icontains',
      name: T('sku'),
      pk: 1,
      active: true,
      fieldProps: {
        placeholder: T('sku'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'currency_type',
      name: T('currency.type'),
      pk: 2,
      active: true,
      fieldProps: {
        placeholder: T('currency.type'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'current_scheduled_price__period__startswith',
      name: T('current.sale.price'),
      pk: 3,
      active: true,
      fieldProps: {
        placeholder: T('current.sale.price'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'tax_rate',
      name: T('tax'),
      pk: 4,
      active: true,
      fieldProps: {
        placeholder: T('tax'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'retail_price__gte',
      name: T('min.psf'),
      pk: 5,
      active: true,
      fieldProps: {
        placeholder: T('min.psf'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'retail_price__lte',
      name: T('max.psf'),
      pk: 6,
      active: true,
      fieldProps: {
        placeholder: T('max.psf'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'extra_field__offer_price__gte',
      name: T('min.offer.price'),
      pk: 7,
      active: true,
      fieldProps: {
        placeholder: T('min.offer.price'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'extra_field__offer_price__lte',
      name: T('max.offer.price'),
      pk: 8,
      active: true,
      fieldProps: {
        placeholder: T('max.offer.price'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'price',
      name: T('selling.price'),
      pk: 9,
      active: true,
      fieldProps: {
        placeholder: T('selling.price'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'extra_field__offer_price',
      name: T('offer.price'),
      pk: 10,
      active: true,
      fieldProps: {
        placeholder: T('offer.price'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'current_scheduled_price__period__startswith__gte',
      name: T('current.price.start'),
      pk: 11,
      active: true,
      fieldProps: {
        placeholder: T('current.price.start'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'current_scheduled_price__period__endswith__lte',
      name: T('current.price.end'),
      pk: 12,
      active: true,
      fieldProps: {
        placeholder: T('current.price.end'),
      },
    },
  ];

  const columns = [
    {
      key: 0,
      dataIndex: 'id',
      title: 'ID',
      // editable: true,
    },
    {
      key: 1,
      dataIndex: 'sku',
      title: T('sku'),
      render: (cellData, row) => (
        <a onClick={() => onSkuClick(row)}>{cellData}</a>
      ),
    },
    {
      key: 2,
      dataIndex: 'currency_type',
      title: T('currency.type'),
    },
    {
      key: 3,
      dataIndex: 'tax_rate',
      title: T('tax'),
    },
    {
      key: 4,
      dataIndex: 'retail_price',
      title: T('psf'),
      editable: true,
    },
    {
      key: 5,
      dataIndex: 'price',
      title: T('selling.price'),
      editable: true,
    },
    {
      key: 6,
      dataIndex: 'extra_field.offer_price',
      title: T('offer.price'),
      editable: true,
    },
    {
      key: 7,
      dataIndex: 'current_scheduled_price.price',
      title: T('current.sale.price'),
    },
    {
      key: 8,
      dataIndex: 'current_scheduled_price.period.lower',
      title: T('current.price.start'),
      render: (cellData) => (cellData ? dateFormatter(cellData) : ''),
    },
    {
      key: 9,
      dataIndex: 'current_scheduled_price.period.upper',
      title: T('current.price.end'),
      render: (cellData) => (cellData ? dateFormatter(cellData) : ''),
    },
    {
      key: 10,
      dataIndex: 'price_list__code',
      title: 'price_list__code',
      editable: true,
      visible: false,
      className: 'hide',
    },
  ];

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    setState((prevState) => ({ ...prevState, selectedRow: row }));
  };

  const onSkuClick = (row) => {
    const { sku, price_list__code } = row;
    const param = `?product_price__sku=${encodeURIComponent(
      sku
    )}&product_price__price_list__code=${price_list__code}`;
    // onNavigate(`/price/price-list-optimization/price-detail-list/scheduledproductprice/${param}`);

    history.push({
      pathname: `/price/price-list-optimization/price-detail-list/scheduledproductprice/${param}`,
      code: price_list__code,
      sku: sku,
      param: `?product_price__sku=${sku}&product_price__price_list__code=${price_list__code}&product_price__price_list__id=${pricelist.id}`,
    });
  };
  let bulkProductsPriceInterval;
  const priceListUpload = (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append('filename', file);

    // Append default timezone to form body.
    const timezone = { timezone: 'Europe/Istanbul' };
    bodyFormData.append('context', JSON.stringify(timezone));

    saveCurrentProductPrice(bodyFormData).then((res) => {
      const { tracking_slug } = res;
      if (tracking_slug) {
        bulkProductsPriceInterval = setInterval(
          () => checkProductPriceCacheKey(tracking_slug),
          2500
        );
        setState((prevState) => ({
          ...prevState,
          productPriceUpdateDisabled: true,
          productPriceUpdateStart: true,
        }));
      }
    });
    return false;
  };

  const checkProductPriceCacheKey = (tracking_slug) => {
    getBulkProductPriceStatus(tracking_slug)
      .then((result) => {
        if (
          result &&
          result.status === 'succeeded' &&
          bulkProductsPriceInterval
        ) {
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
          message.success('İşlem tamamlanmıştır.');
        } else if (
          result &&
          result.status === 'failed' &&
          bulkProductsPriceInterval
        ) {
          message.error('İşlem Başarısız');
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
        }
      })
      .catch(() => {
        if (bulkProductsPriceInterval) {
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
        }
      });
  };

  const onChangeRow = (row) => {
    const { price, extra_field, retail_price } = row;
    const { id } = state.selectedRow;
    const paramsAttribute = {
      price,
      extra_field,
      retail_price,
    };
    saveProductPrice(id, paramsAttribute).then(() => {
      // onNavigate(`/price/price-list-optimization/price-detail-list/${id}/${code}`);
    });
  };

  const onRemoveRow = () => {
    const { id } = state.selectedRow;
    deleteProductPrice(id).then(() => {
      dataTableRef?.current?.reload();
    });
  };

  const onDownloadClick = (e, url, index) => {
    const newLoadings = [...state.isDownloading];
    const token = store.getState().authReducer.token;
    newLoadings[index] = true;
    setState((prevState) => ({
      ...prevState,
      isDownloading: newLoadings,
    }));
    e.stopPropagation();
    axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Accept-Language': getAPILanguage(),
          'Content-Type': 'application/text',
          Authorization: `${tokenHeaderKEY} ${token}`,
        },
      })
      .then((response) => {
        const respHeaders = response.headers;
        const blob = new Blob([response.data], {
          type: respHeaders['content-type'],
        });
        const filename = respHeaders['content-disposition']
          .split('=')
          .slice(1)[0];
        FileSaver.saveAs(blob, filename);
        setState((prevState) => ({
          ...prevState,
          isDownloading: [false, false],
        }));
      });
  };

  return (
    <LayoutContentWrapper className="price-optimization-product-list">
      {state.excelToggle && (
        <Block className="excel-update-panel">
          <Block className="col col-start">
            <Block className="excel-update-panel__title">
              {T('update.bulk.price.list')}
            </Block>
          </Block>
          <Block className="col col-end excel-update-panel__link-group">
            <Button
              type="primary"
              className="preproduct-button-action"
              loading={state.isDownloading[0]}
              onClick={(e) =>
                onDownloadClick(
                  e,
                  baseURLSet.default +
                    priceOptimizationProductPrice +
                    `excel_export_with_import_template/?price_list__code=${code}`,
                  0
                )
              }
              icon="icon-doc"
            >
              {T('template.price.download')}
            </Button>
            <Button
              type="primary"
              className="preproduct-button-action"
              icon="icon-doc"
              loading={state.isDownloading[1]}
              onClick={(e) =>
                onDownloadClick(
                  e,
                  baseURLSet.default +
                    priceOptimizationProductPrice +
                    `excel_export/?price_list__code=${code}`,
                  1
                )
              }
            >
              {T('current.price.download')}
            </Button>
          </Block>
          <Block className="col w-100">
            <p className="w-100">{T('page.price.scheduled.upload.desc')}</p>
            <Upload
              className=""
              fileList={[]}
              beforeUpload={priceListUpload}
              multiple={false}
              accept=".xls,.xlsx,.csv"
              disabled={state.productPriceUpdateDisabled}
            >
              <Button
                type="secondary"
                loading={state.productPriceUpdateDisabled}
              >
                {T('current.price.upload')}
              </Button>
            </Upload>
          </Block>
        </Block>
      )}

      <DatatableWithFilter
        filterProps={{ staticFilters: filterInputs }}
        datatableProps={{
          ref: dataTableRef,
          saveable: true,
          saveableFixed: 'right',
          rowKey: 'id',
          columns: columns,
          rowSelection,
          actionButtons,
          selectedRowKeys,
          onRowClick,
          subtitle: `${pricelist?.name} ${T('price.list')}`,
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(T('price.list')),
          onChangeRow,
          url: addQueryToURL(priceOptimizationProductPrice, {
            price_list: id,
          }),
          onRemoveRow,
          doubleClickable: false,
          exportable: false,
          loading: state.productPriceUpdateStart
            ? state.productPriceUpdateDisabled
            : undefined,
        }}
      />
    </LayoutContentWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    pricelist: state.priceOptimizationReducer.pricelist,
  };
};

const mapDispatchToProps = {
  getPriceListCode,
  saveProductPrice,
  deleteProductPrice,
  saveCurrentProductPrice,
  getBulkProductPriceStatus,
  deleteBulkProductPrices,
  addOrDeletePricesBulkStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizationProductPrice);
