import React, { useState } from 'react';

import { priceOptimizationPriceList } from '@constants/serviceUrls';
import { navigator } from '@common/navigation';
import { sendRequest } from '@common/network';
import { isDefinedAndNotEmpty } from '@utils';
import { T } from '@utils/languageProvider';
import {
  formLayoutTypes,
  httpMethods,
  validationTypes,
} from '@constants/commontypes';
import { inputTypes } from '@components/DynamicForm/constants';

import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';

const DynamicForm = DynamicFormWithBox('price-list-optimization');

const PriceListOptimizationForm = () => {
  const [state, setState] = useState({
    loading: false,
    inputShow: false,
    rules: false,
  });

  const onNavigate = (url) => {
    navigator.push(url);
  };
  const generateFormInputs = () => {
    const { inputShow, rules } = state;
    return [
      {
        pk: 0,
        name: T('price.list.name'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('price.list.name'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('price.list.name')),
        },
      },
      {
        pk: 1,
        name: T('price.list.code'),
        key: 'code',
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('price.list.code'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('price.list.code')),
        },
      },
      {
        pk: 2,
        name: 'Remote ID',
        key: 'remote_id',
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required('Remote ID')],
        },
        fieldProps: {
          placeholder: T('enter.variable').format('Remote ID'),
        },
      },
    ];
  };
  const onChange = (values) => {
    if (isDefinedAndNotEmpty(values.is_auto_sync)) {
      setState({
        rules: values.is_auto_sync === 'true',
        inputShow: values.is_auto_sync === 'true',
      });
    }
  };
  const getParams = (values) => {
    return {
      code: values.code ? values.code : null,
      name: values.name,
      remote_id: Number(values.remote_id),
    };
  };
  const onSubmit = (values) => {
    sendRequest({
      url: priceOptimizationPriceList,
      method: httpMethods.POST,
      params: getParams(values),
      successMessage: T('add.success').format(T('price.list')),
      onBegin: () => {
        setState({
          ...state,
          loading: true,
        });
      },
      onSuccess: () => {
        setState({
          ...state,
          loading: false,
        });
        onNavigate(`/price/price-list-optimization/price-list`);
      },
      onFinally: () => {
        setState({
          ...state,
          loading: false,
        });
      },
    });
  };

  const inputFields = generateFormInputs();
  return (
    <LayoutContentWrapper className="price-list-form">
      <DynamicForm
        title={T('add.new.price.list')}
        subtitle={T('price.list.form.desc')}
        onSubmit={onSubmit}
        onChange={onChange}
        inputs={inputFields}
        submitting={state.loading}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

export default PriceListOptimizationForm;
