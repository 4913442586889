import React from 'react';
import { Icon } from 'antd';

import { getRandom } from '@utils';
import { T } from '@utils/languageProvider';

export const defaultHorizontalScroll = 1500;
export const defaultVerticalScroll = 400;
export const pageSizeLimitForMultiplePagination = 7;
export const paginationPosition = {
  both: 'both',
  top: 'top',
  bottom: 'bottom',
};
export const defaultPagination = {
  simple: false,
  showSizeChanger: true,
  showQuickJumper: true,
  // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  pageSize: 20,
  pageSizeOptions: ['20', '50', '100', '250'],
  hideOnSinglePage: false,
  position: paginationPosition.both,
};
export const defaultColumnProps = {
  align: 'left',
};
export const locale = {
  filterConfirm: T('okay'),
  filterReset: T('clean'),
  emptyText: T('not.found.appropriate.data'),
};
export const draggableColumn = {
  key: 'drag-'.concat(getRandom()),
  width: '10px',
  title: '',
  render: () => <Icon type="menu" />,
};
