import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import { createURL } from '@utils/';
import { T } from '@utils/languageProvider';

import { GET_OPTIMIZATION_PRICE_LIST_SUCCESS } from '../actiontypes';

import {
  bulkoperationUlr,
  priceOptimizationPriceList,
  priceOptimizationProductPrice,
  priceOptimizationScheduledProductPrice,
} from '@constants/serviceUrls';

export function getPriceListCode(id) {
  return async () => {
    return sendRequest({
      url: id
        ? createURL(priceOptimizationPriceList, id)
        : priceOptimizationPriceList,
      onSuccess: (result) => {
        return {
          type: GET_OPTIMIZATION_PRICE_LIST_SUCCESS,
          payload: result,
        };
      },
    });
  };
}
export function savePriceList(id, params) {
  return async () => {
    return sendRequest({
      url: id
        ? createURL(priceOptimizationPriceList, [id])
        : priceOptimizationPriceList,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('price.list'))
        : T('add.success').format(T('price.list')),
      params,
    });
  };
}
export function saveProductPrice(id, params) {
  return async () => {
    return sendRequest({
      url: id
        ? createURL(priceOptimizationProductPrice, [id])
        : priceOptimizationProductPrice,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('price.list'))
        : T('add.success').format(T('price.list')),
      params,
    });
  };
}
export function deleteProductPrice(id) {
  return async () => {
    return sendRequest({
      url: createURL(priceOptimizationProductPrice, [id]),
      method: httpMethods.DELETE,
      successMessage: T('delete.success').format(T('price.list')),
    });
  };
}
export function saveScheduledProductPrice(id, params) {
  return async () => {
    return sendRequest({
      url: id
        ? createURL(priceOptimizationScheduledProductPrice, [id])
        : priceOptimizationScheduledProductPrice,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      successMessage: id
        ? T('update.success').format(T('price.list'))
        : T('add.success').format(T('price.list')),
      params,
    });
  };
}

// Excel
export function saveCurrentProductPrice(params) {
  return async () => {
    return sendRequest({
      url: createURL(priceOptimizationProductPrice, ['excel_import']),
      successMessage: 'İşlem başlamıştır...',
      method: httpMethods.POST,
      params,
    });
  };
}

export function saveCurrentScheduledProductPrice(params) {
  return async () => {
    return sendRequest({
      url: createURL(priceOptimizationScheduledProductPrice, ['excel_import']),
      method: httpMethods.POST,
      params,
    });
  };
}

// bulkoperation
export function getBulkProductPriceStatus(id) {
  return async () => {
    return sendRequest({
      url: createURL(bulkoperationUlr, id),
      successMessage: T('add.success').format(T('price.list')),
    });
  };
}
