import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import set from 'lodash/set';
import get from 'lodash/get';
import merge from 'lodash/merge';
import sample from 'lodash/sample';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import qs from 'qs';
import Button from '@components/Button';

import { filterTypes, colors, actionCommands } from '@constants/commontypes';
import {
  defaultDateFormat,
  defaultTimeFormat,
  defaultTimeFormatWithTimeZone,
  defaultTimeFormatWithSeconds,
  dateWithMonthName,
} from '@constants';
import { T } from '@utils/languageProvider';
import { history, navigator } from '@common/navigation';
import { contextChannel } from '@constants/';

import Image from '@components/Image';
import Label from '@components/Label';
import route from '@components/Route';
import AuthorizationInfo from '@components/AuthorizationInfo';
import Intrinsic from '@components/Intrinsic';
import Span from '@components/Span';
import CopyIcon from '@components/CopyIcon';
import Icon from '@components/Intrinsic';

import simpleProductImage from '@assets/images/simple-product.svg';
import variantProductImage from '@assets/images/variant-product.svg';
import groupProductImage from '@assets/images/group-product.svg';
import bundleProductImage from '@assets/images/bundle-product.svg';
import miscProductImage from '@assets/images/misc-product.svg';
import noImage from '@assets/images/noimage.png';
import { readStorageItem, writeStorageItem } from '@common/storage';
import { getContextChannelInfo, logResponseMessages } from '@common/index';
import Block from '@components/Block';
import { downloadURL } from '@constants/serviceUrls';
import FileSaver from 'file-saver';

import * as bulkUpdatesAction from '@resources/actions/bulkUpdates';
import { isFunction } from 'lodash/fp';
import { store } from '@resources/store';

export function isDefined(obj) {
  return obj !== undefined && obj !== null;
}

export function isDefinedAndNotEmpty(obj) {
  if (typeof obj === 'string') return obj !== '';
  else if (Array.isArray(obj)) return !isEmpty(obj) && obj.length;
  else return isDefined(obj);
}

export function isBase64(str) {
  if (str === '' || str.trim() === '') {
    return false;
  }

  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

export function hasHTMLTag(str) {
  if (str === '' || str.trim() === '') {
    return false;
  }

  return /<\/?[a-z][\s\S]*>/i.test(str);
}

export function createURL(url, routes, queryObject) {
  return addQueryToURL(
    addRoutesToURL(
      url,
      typeof routes === 'string' ? routes.split('/') : routes
    ),
    queryObject
  );
}

export function addRoutesToURL(url = '', routes) {
  if (!routes) return url;
  if (routes instanceof Array) {
    url = url.length
      ? url[url.length - 1] === '/'
        ? url
        : url.concat('/')
      : url;
    for (let index = 0; index < routes.length; index++) {
      const element = routes[index];
      if (isDefinedAndNotEmpty(element))
        url = url.concat(`${encodeURIComponent(element)}/`);
    }
    return url;
  }
  if (typeof routes === 'object') {
    url = '/'.concat(url);
    for (const key in routes) {
      if (routes.hasOwnProperty(key)) {
        const element = routes[key];
        if (isDefinedAndNotEmpty(element))
          url = url.concat(`${encodeURIComponent(element)}/`);
      }
    }
    return url;
  }
  return url;
}
/**
 * Creates URL Query string by queryObject
 *
 * if 'isConvertMultipleValues' is true and queryObject's key is array then it will be converted to query string one by one and concatenated automatically
 *
 * Ex:
 * ```js
 * queryObject={
 *  pk: 1,
 *  product_category: [11, 22],
 * }
 * ```
 * will be converted to pk=1&product_category=11&product_category=22
 *
 * @param {string} url
 * @param {[{[key:string]:string|number|string[]|number[]}]} queryObject
 * @param {boolean?} isQuery
 * @param {boolean?} isEncodeURI
 * @param {boolean?} isConvertMultipleValues
 * @returns {string}
 */
export const addQueryToURL = (
  url = '',
  queryObject = {},
  isQuery = false,
  isEncodeURI = false,
  isConvertMultipleValues = false
) => {
  const queryArray = [];
  const encodeURIMethod = isEncodeURI ? encodeURI : encodeURIComponent;

  const convertUrlQuery = (key, value) => {
    return `${encodeURIMethod(key)}=${encodeURIMethod(value)}`;
  };

  Object.entries(queryObject).forEach(([key, value]) => {
    if (isDefinedAndNotEmpty(value)) {
      if (isConvertMultipleValues && Array.isArray(value)) {
        value.forEach((data) => {
          queryArray.push(convertUrlQuery(key, data));
        });
      } else {
        queryArray.push(convertUrlQuery(key, value));
      }
    }
  });

  if (queryArray.length > 0) {
    return isQuery
      ? `${url}&${queryArray.join('&')}`
      : `${url}?${queryArray.join('&')}`;
  }

  return url;
};

export function serializeObjectWithDot(obj) {
  if (!obj || typeof obj !== 'object') return obj;
  let serializedObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const element = obj[key];
      const isDotted = key.indexOf('.') !== -1;
      if (isDotted) set(serializedObject, key, element);
      else serializedObject[key] = element;
    }
  }
  return serializedObject;
}

export function concatQueryToURL(url, queryString) {
  return queryString ? url.concat('&', queryString) : url;
}

export function getObjectFromString(valuePath, obj) {
  return get(obj, valuePath);
}

export function setObjectFromString(obj, valuePath, value) {
  return set(obj, valuePath, value);
}

export function filterByObject(data, filterObject, filterOptions) {
  if (!data || !filterObject) return data;
  return data.filter((item) => objectFilter(item, filterObject, filterOptions));
}

function objectFilter(item, filterObject, filterOptions) {
  for (const key in filterObject) {
    if (filterObject.hasOwnProperty(key)) {
      const element = filterObject[key];
      if (
        (item === undefined && element === undefined) ||
        (item === null && element === null)
      )
        continue;
      if (!item.hasOwnProperty(key)) {
        return false;
      }
      if (typeof element === 'object') {
        if (!objectFilter(item[key], element)) return false;
      } else {
        let filter = filterTypes.equals;
        if (filterOptions) {
          filter = filterOptions.find((filter) => filter.name === key);
          filter =
            filter && filter.filterType
              ? filter.filterType
              : filterTypes.equals;
        }
        if (!filter(item[key], element)) return false;
      }
    }
  }
  return true;
}

export function mergeObjects(firstObj, secondObj) {
  return merge(firstObj, secondObj);
}

export function getSmallImageSource(src) {
  if (!src) return '';
  let extensionPointer = src.lastIndexOf('.');
  return src
    .substring(0, extensionPointer)
    .concat('_size50x50')
    .concat(src.substring(extensionPointer, src.length));
}

export function getRandom(max = 10000) {
  return (new Date().getTime() * Math.random()) % (max + 1);
}

export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function arrayEqual(arrA, arrB) {
  if (arrA === arrB) {
    return true;
  }

  if (!(arrA instanceof Array) || !(arrB instanceof Array)) {
    return false;
  }
  if (arrA.length !== arrB.length) {
    return false;
  }
  for (let i = 0; i < arrA.length; i++) {
    // eslint-disable-next-line
    if (arrA[i] != arrB[i]) {
      return false;
    }
  }

  return true;
}

export function printDay(item) {
  let day = Math.abs(item) > 1 ? T('days') : T('day');
  return `${item} ${day}`;
}

export function dateFormatter(dateObject, timeFormatting = false) {
  if (!isDefinedAndNotEmpty(dateObject)) return '';
  if (timeFormatting)
    return moment(dateObject).format(
      defaultDateFormat + ' ' + defaultTimeFormat
    );
  return moment(dateObject).format(defaultDateFormat);
}

export function renderDateObject(dateObject = new Date()) {
  if (!isDefinedAndNotEmpty(dateObject)) return '';
  return moment(dateObject);
}

export function timeFormatter(time = '') {
  return moment(time, defaultTimeFormatWithTimeZone).format(
    defaultTimeFormatWithSeconds
  );
}

export const addTimezoneHourToDate = (date) => {
  const timezoneHour = moment().utcOffset() / 60;
  return moment(date).add(timezoneHour, 'hours').format('DD/MM/YYYY HH:mm');
};

export function removeTimezoneHourToDate(date) {
  const timezoneHour = moment().utcOffset() / 60;
  return moment(date)
    .subtract(timezoneHour, 'hours')
    .format('DD/MM/YYYY HH:mm');
}

export function convertTimeToUtc(
  time,
  defaultFormat = defaultTimeFormat,
  convertFormat = defaultTimeFormat
) {
  return moment.utc(moment(time, defaultFormat)).format(convertFormat);
}

export function convertTimeToLocal(
  time,
  defaultFormat = defaultTimeFormat,
  convertFormat = defaultTimeFormat
) {
  return moment.utc(time, defaultFormat).local().format(convertFormat);
}

export function packageStatusColorRender(index, count) {
  if (count > 0) {
    if (colors[index]) {
      return colors[index];
    }
    return sample(colors);
  }
  return '#232b3b';
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function isColorLight(color) {
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (hsp > 127.5) {
    return true;
  }
  return false;
}

export function getSmallImageFormatter(imgSrc, { name, parent }) {
  const hasParent = isDefined(parent) && (
    <Intrinsic className="icon-arrow-product" />
  );
  const cellSrc = getSmallImageSource(imgSrc);
  return cellSrc ? (
    <div className="image-wrapper">
      {hasParent}
      <Image className="cell-image" src={cellSrc} />
    </div>
  ) : (
    <div className="image-wrapper">
      {hasParent}
      <Image className="cell-image" src={noImage} />
    </div>
  );
}

export const productIconClasses = [
  { title: T('simple.product'), image: simpleProductImage },
  { title: T('variant.product'), image: variantProductImage },
  { title: T('bundle.product'), image: bundleProductImage },
  { title: T('grouped.product'), image: groupProductImage },
  { title: T('miscellaneous.product'), image: miscProductImage },
];

export const getProductTypeFormatter = (cellData) => {
  if (parseInt(cellData) >= 0) {
    return (
      <div className="product-type-box">
        <Tooltip title={productIconClasses[parseInt(cellData)].title}>
          <Image src={productIconClasses[parseInt(cellData)].image} />
        </Tooltip>
      </div>
    );
  }
};

export function hex2RGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getAllUrlParams(url) {
  let queryString = decodeURIComponent(
    url ? url.split('?')[1] : window.location.search.slice(1)
  );
  let obj = {};

  if (queryString) {
    queryString = queryString.split('#')[0];
    let arr = queryString.split('&');
    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].split('=');
      let paramName = a[0];
      let paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // istek atilirken parametelere toLowerCase ugyulandigi icin exact olan fieldlar calismiyor, bu yuzden comment'e alindi.
      // paramName = paramName.toLowerCase();
      // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      if (paramName.match(/\[(\d+)?\]$/)) {
        let key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];
        if (paramName.match(/\[\d+\]$/)) {
          let index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export function isEmptyFn(f) {
  return (
    typeof f === 'function' &&
    /^function[^{]*[{]\s*[}]\s*$/.test(Function.prototype.toString.call(f))
  );
}

export function booleanFormatter(cellData) {
  return <Label>{cellData ? T('yes') : T('no')}</Label>;
}

export function yesNoFormatter(cellData) {
  return <Label>{cellData ? T('yes') : T('no')}</Label>;
}

export function statusBoolExistinceFormatter(cellData) {
  return (
    <Label>
      {isDefinedAndNotEmpty(cellData) ? T('exist') : T('not.exist')}
    </Label>
  );
}

export function cancellationTypeFormatter(cellData) {
  return (
    <Label>{cellData === 'cancel' ? T('cancellation') : T('return')}</Label>
  );
}

export function statusRenderer(statusArr, cellData) {
  const status = statusArr.find((item) => item.value === cellData);

  return status?.label ?? cellData;
}

export function inputRenderer(staticInputs, id, formData) {
  return id
    ? [
        ...staticInputs.map((input) => {
          const inputValue = formData && formData[input.key];
          if (inputValue instanceof Array) {
            return {
              ...input,
              default_value: input.default_value || inputValue,
            };
          } else if (inputValue instanceof Object) {
            return {
              ...input,
              default_value:
                input.default_value ||
                JSON.stringify(isDefined(inputValue) ? inputValue : '{}'),
            };
          } else if (inputValue instanceof Boolean) {
            return {
              ...input,
              default_value:
                input.default_value ||
                (isDefined(inputValue) ? 'True' : 'False'),
            };
          } else if (typeof inputValue === 'boolean') {
            return {
              ...input,
              default_value: inputValue,
            };
          } else {
            return {
              ...input,
              default_value:
                input.default_value ||
                (isDefined(inputValue) ? `${inputValue}` : ''),
            };
          }
        }),
      ]
    : staticInputs;
}

export function createAuthorizedPage(component, actionName, unAuthorizedURL) {
  return route({
    component: component,
    actionName: actionName,
    unAuthorizedRender: <AuthorizationInfo />,
    unAuthorizedURL: unAuthorizedURL,
  });
}

/**
 * Forces router to rerender when destination path and target path renders same component
 * @param {component} Component
 */
export const forceRerender = (Component) => (props) =>
  <Component {...props} key={props.location.pathname} />;

export const redirectToSignInPage = (callback) => {
  const { pathWithoutLanguage } = extractLanguageFromPathname(
    history.location.pathname
  );
  const { channelPk } = extractChannelPkFromPathname(pathWithoutLanguage);
  if (channelPk) {
    writeStorageItem(contextChannel, channelPk);
    navigator.channelPk = channelPk;
  }

  if (
    history.location &&
    (history.location.pathname === '/forgotpassword' ||
      history.location.pathname.indexOf('/auth/resetPassword') > -1)
  ) {
    history.push({ pathname: history.location.pathname });
  } else if (
    history.location &&
    history.location.pathname !== '/price/signin'
  ) {
    history.push({
      pathname: '/price/signin',
    });
  } else history.push({ pathname: '/price/signin' });

  if (callback && typeof callback === 'function') {
    callback();
  }
};

export const getEntries = (obj) =>
  Object.keys(obj).map((key) => [key, obj[key]]);

export const extractChannelPkFromPathname = (pathname) => {
  const splittedPath = pathname.split('/');
  const [, , channel, ...pathSplittedWithoutChannel] = splittedPath;
  const channelPk = Number(channel) || readStorageItem(contextChannel);
  const pathWithoutChannel = splittedPath.some((path) =>
    ['channel', 'marketplace'].includes(path)
  )
    ? `/${pathSplittedWithoutChannel.join('/')}`
    : pathname;

  return { channelPk, pathWithoutChannel };
};

/**
 * Returns `'channel'`, `'marketplace'` or `null` if url matches any channel based url like `/channel/1/...`.
 */
export const getChannelContext = () => {
  const contextRegExp = /^\/.*?\/.*?\/(?<context>channel|marketplace)\/\d+\//;
  const match = contextRegExp.exec(window.location.pathname);
  return match?.groups?.context;
};

export const extractLanguageFromPathname = (pathname) => {
  const splittedPath = pathname.split('/');
  const [, platformLang, channelLang, ...pathSplittedWithoutLanguage] =
    splittedPath;
  const pathWithoutLanguage = `/${pathSplittedWithoutLanguage.join('/')}`;
  const lang = { platform: platformLang, channel: channelLang };
  return { lang, pathWithoutLanguage };
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileBase64(file) {
  if (file) {
    const { name, uid } = file;
    const result = await getBase64(file);
    return {
      name,
      uid,
      status: 'done',
      thumbUrl: result,
    };
  }
}

export function unifiedIconRenderer(cellData, includeUnifiedOrder) {
  const tooltipTitleLangKey = [
    ['not.unified.product', 'unified.product'],
    ['not.unified.include', 'unified.include'],
  ];

  const tooltipTitle = T(
    tooltipTitleLangKey[
      Number(includeUnifiedOrder === 'include_unified_order')
    ][Number(!!cellData)]
  );
  return (
    <Tooltip title={tooltipTitle}>
      <Intrinsic
        className={classNames(['icon-unified header-icon', { red: cellData }])}
      />
    </Tooltip>
  );
}

export function latentNameRenderer(cellData) {
  return <Tooltip title={cellData}>{cellData}</Tooltip>;
}

export function statusActivePassiveFormatter(cellData) {
  return (
    <Label className={cellData ? 'green' : 'red'}>
      {T(cellData ? 'active' : 'passive')}
    </Label>
  );
}
export function statusActivePassiveStringFormatter(cellData) {
  return (
    <Label className={cellData === 'active' ? 'green' : 'red'}>
      {T(cellData)}
    </Label>
  );
}

export function statusUsedPassiveFormatter(cellData) {
  return (
    <Label className={cellData === 'active' ? 'green' : 'red'}>
      {T(cellData)}
    </Label>
  );
}

export function statusSuccessFailFormatter(cellData) {
  return (
    <Label className={cellData ? 'green' : 'red'}>
      {T(cellData ? 'successful' : 'unsuccessful')}
    </Label>
  );
}

export function dataTableExtendedLine(cellData, rowData) {
  return (
    <Block className="table-line">
      <Span>{cellData}</Span>
      <CopyIcon
        index={`response${rowData.pk}`}
        text={cellData}
        textVisible={false}
      ></CopyIcon>
    </Block>
  );
}

export const Deferred = () => {
  let resolve;
  let reject;

  return {
    promise: new Promise((...args) => {
      [resolve, reject] = args;
    }),
    resolve,
    reject,
  };
};

export function emptyValueRenderer(value) {
  return isDefinedAndNotEmpty(value) ? value : '-';
}

// source: https://stackoverflow.com/a/30810322
function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

// source: https://stackoverflow.com/a/30810322
export function copyTextToClipboard(text, cb = () => {}) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      cb(text);
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const extractId = ({
  match: {
    params: { id },
  },
}) => id;

export function readImgAsUrl(delegate, event) {
  const reader = new FileReader();
  reader.onloadend = function () {
    delegate(reader.result);
  };
  if (event.target.files[0]) {
    reader.readAsDataURL(event.target.files[0]);
    return reader.result;
  }
}

export const requiredLabelRenderer = (label) => {
  return (
    <>
      {' '}
      {label} <Span className="red">*</Span>
    </>
  );
};

export const fullNameRenderer = (customer) => {
  return (
    customer &&
    `${customer.first_name && customer.first_name} ${
      customer.last_name && customer.last_name
    }`
  );
};

export const dateValueFormatter = (
  value = new Date(),
  dateFormat,
  dateValueFormat
) => {
  if (!isDefinedAndNotEmpty(value)) return '';
  return moment(value, dateFormat).format(dateValueFormat);
};

export const convertObjectForType = (values, convertToUndefined = true) => {
  return JSON.parse(
    JSON.stringify(values, (key, value) => {
      if (!isNaN(value) && Number.isInteger(parseFloat(value))) {
        return parseInt(value);
      }

      if (['true', 'false'].indexOf(value) !== -1) {
        return value !== 'false';
      }

      return value === undefined && convertToUndefined ? null : value;
    })
  );
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

/* Fazla söze gerek yok, BE den gelen verinin bu şekilde olmasından kaynaklı URL'lerin terkar değiştirimesi için yapıldı :/ */
export const updateImageUrlWithAttributes = (
  imageSrc,
  imageURLUpdate,
  name,
  attributesKwargs
) => {
  if (!imageSrc || !imageURLUpdate) return false;
  const nameSplitted = name?.replace(/(\[).+?(\])/g, `$1${0}$2`).split('.');
  const pureKey = nameSplitted?.shift();
  const lastKey = nameSplitted?.pop();
  const keyWithValue = `[${pureKey}][value][${lastKey}][url]`;
  const keyWithUrl = `[${pureKey}][url]`;
  const url =
    get(attributesKwargs, keyWithValue) ?? get(attributesKwargs, keyWithUrl);
  const cdn = url?.split('/').slice(0, 3).join('/');
  if (imageSrc.includes('base64,')) return imageSrc;
  return `${cdn}/${imageSrc}`;
};
export const waitInitialData = (id, initial) => (id ? initial : undefined);

export const dateMonthFormat = (date) => moment(date).format(dateWithMonthName);
export const createClickableColumn = (
  rowTitle = '',
  rowDetails = {},
  baseURL = ''
) => {
  const redirectionPageURL = baseURL + rowDetails?.pk;
  const clickableColumnElement = (
    <a href={redirectionPageURL} onClick={(event) => event.preventDefault()}>
      {rowTitle}
    </a>
  );

  return clickableColumnElement;
};

/*
 Ihtiyaç: Bazı dinamik alanlarda undefined set edemediğimiz için buna ihtiyaç duyuyoruz
 Amaç: Bazı objelerin BE tarafına gönderilmemesi gerekmektedir.
 Sonuç: null olarak set edebildiğimiz objeleri undefined yaparak çözüyoruz
 Kullanım: objectKeys array olarak kabul edilir ve boş ise tüm öğelerde denetleme yapılır,
           dolu gelir ise sadece key değerleri ile uyuşan olursa dönüşüm yapılır
*/
export const convertObjectNullToUndefined = (values, objectKeys) => {
  return JSON.parse(
    JSON.stringify(values, (key, value) => {
      if (
        (!objectKeys?.length && value === null) ||
        (objectKeys?.includes(key) && value === null)
      ) {
        return undefined;
      }

      return value;
    })
  );
};

export const logComplexErrorMessage = (err) => {
  const errorMessages = err?.messages?.[0];

  if (Object.keys(errorMessages)?.length) {
    Object.keys(errorMessages).forEach((key) => {
      const messagesObject = errorMessages[key];
      if (Object.keys(messagesObject)?.length) {
        Object.keys(messagesObject).forEach((item) => {
          let messageDetail = Array.isArray(messagesObject[item])
            ? messagesObject[item][0]
            : messagesObject[item];
          messageDetail = `${item}: ${messageDetail}`;
          logResponseMessages({ type: 'error', message: messageDetail });
        });
      }
    });
  }
};

export const arrayToString = (arr, key, splitter = ',') => {
  let result = '';

  if (!arr) return result;

  for (let i = 0; i < arr.length; i++) {
    let value = arr[i][key];

    if (Array.isArray(arr[i][key]) && arr[i][key].length) {
      value = arr[i][key][0];
    }

    result += `${value}${i === arr.length - 1 ? '' : splitter}`;
  }

  return result;
};

export const selectOptions = (options, keyName) =>
  options?.map?.((option) => ({
    pk: option[keyName],
    label: option.label,
    value: option[keyName],
  })) ?? [];

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// In memory export queue. Consists of cache_key values.
// Prevents recursive processBulkItem function to get called multiple times
// for the same cache_key.
export const BULK_QUEUE = [];

/**
 * Bulk update helper method.
 *
 * @param {*} param0
 * @param {*} callback
 */
export const bulkFileUpdate = async (
  {
    bulkAction,
    bulkActionArgs,
    cacheKeyActionName = null,
    cacheKeyActionArgs = null,
  } = {},
  callback = () => {}
) => {
  // Check if cache key exists, so continue with bulk action
  let bulkUpdateInfo = JSON.parse(readStorageItem('bulkUpdateInfo')) ?? [];
  const condition =
    bulkUpdateInfo.find((bulk) => {
      return (
        bulk.cacheKeyActionName === cacheKeyActionName &&
        bulk.actionArgs[0] === cacheKeyActionArgs[0]
      );
    }) ?? {};

  if (isEmpty(condition) && isFunction(bulkAction)) {
    // Get the cache key
    const { cache_key } = await bulkAction(...bulkActionArgs);
    const actionArgs = cacheKeyActionArgs
      ? [cache_key, ...cacheKeyActionArgs]
      : [cache_key];

    bulkUpdateInfo.push({ actionArgs, cacheKeyActionName });
    writeStorageItem('bulkUpdateInfo', JSON.stringify(bulkUpdateInfo));
  }

  for (const bulkItem of bulkUpdateInfo) {
    // Check if bulk item already is being processed,
    // only start processing if it is not in the BULK_QUEUE.
    if (BULK_QUEUE.indexOf(bulkItem.actionArgs[0]) === -1) {
      processBulkItem(bulkItem, callback);
      BULK_QUEUE.push(bulkItem.actionArgs[0]);
    }
  }
};

/**
 * Removes bulk items from the queue given the array of cache_key values.
 * Also updates localStorage.
 *
 * @param {*} bulkIndexList
 * @returns
 */
export const removeCacheKey = (bulkIndexList) => {
  const newBulkUpdateInfo = JSON.parse(readStorageItem('bulkUpdateInfo'));
  const bulkUpdateInfo = newBulkUpdateInfo.filter((bulk) => {
    return !bulkIndexList.includes(bulk.actionArgs[0]);
  });
  writeStorageItem('bulkUpdateInfo', JSON.stringify(bulkUpdateInfo));
  return bulkUpdateInfo;
};

/**
 * Recursive function to handle bulk update operations for single bulk item.
 * Calls itself after 3 seconds delay if process is not completed.
 * Finalizes itself if process is completed or gets an error.
 *
 * @param {*} bulkItem
 * @param {*} callback
 */
export const processBulkItem = async (bulkItem, callback) => {
  try {
    const result = await store.dispatch(
      bulkUpdatesAction[bulkItem.cacheKeyActionName](...bulkItem.actionArgs)
    );

    if (result.status === 'completed' || result.completed === true) {
      removeCacheKey([bulkItem.actionArgs[0]]);
      remove(BULK_QUEUE, (i) => i === bulkItem.actionArgs[0]);
      callback(result);
    } else {
      // Make a recursive call to this method after 3 seconds
      // so process can work until it's completed.
      await sleep(3000);
      processBulkItem(bulkItem, callback);
    }
  } catch (error) {
    if (error.status === 404) {
      removeCacheKey([bulkItem.actionArgs[0]]);
      remove(BULK_QUEUE, (i) => i === bulkItem.actionArgs[0]);
    }
  }
};

/**
 *
 *? @param {*object} value param will be checked whether it is string or not
 *? @returns {boolean} true if given param is string or vice versa
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

/**
 *
 *? @param {*object} filterObject Its DatataTableWithFilter's active filters object
 *? @returns {string} filterQueryURL It's a filter query URL. For example: 'active_catalog_item__isnull=False&active_price__discount=True&airway_volume=1&limit=845'
 */
export function generateFilterQueryURL(filterObject) {
  let filterQueryURL = '';
  filterQueryURL += qs.stringify(filterObject, { indices: false });
  return filterQueryURL;
}

/**
 *
 * ? @param {* file} It's a File object to transform FormData
 * ? @returns {formFile} It's a new FormData that includes the key 'filename' and value 'file' as binary type
 */
export function generateFileFormData(file) {
  let formFile = new FormData();
  formFile.append('filename', file[0].file);
  return formFile;
}

export const saveFileFromResponse = (response) => {
  const respHeaders = response.headers;
  if (
    response.data &&
    respHeaders['content-type'] &&
    respHeaders['content-disposition']
  ) {
    const blob = new Blob([response.data], {
      type: respHeaders['content-type'],
    });
    const filename = respHeaders['content-disposition']
      .split('"')
      .slice(1, -1)[0];
    FileSaver.saveAs(blob, filename);
  }
};

/**
 * @param {string} base64Data - base64 data
 * @param {number} quality  - quality of the image (0-1). Default is 0.9
 * @returns {Promise<string>} - base64 data of the compressed image
 */
export const compressBase64Image = (base64Data, quality = 0.95) => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.src = base64Data;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const data = canvas.toDataURL('image/jpeg', quality);
      resolve(data);
    };
    img.onerror = reject;
  });
};

/**
 * @param {File} originalFile
 * @param {string} newName
 * @returns {File}
 */
export const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

/**
 * Remove turkish accents from string
 * @param {string} str
 * @returns {string}
 */
export const normalizeString = (str) => {
  const charMap = {
    ş: 's',
    Ş: 'S',
    ı: 'i',
    İ: 'I',
    ü: 'u',
    Ü: 'U',
    ğ: 'g',
    Ğ: 'G',
    ö: 'o',
    Ö: 'O',
    ç: 'c',
    Ç: 'C',
  };

  // convert turkish chars to english chars
  let replacedString = str.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
    return charMap[a] || a;
  });

  // remove all other non accepted characters
  replacedString = replacedString.replace(/[^A-Za-z0-9\[\] -_]/g, '');
  return replacedString;
};

export const getNewFilter = (filter = {}, mappingFilter = false) => {
  const filterKeys = Object.keys(filter);
  const newFilters = filterKeys.reduce((previousValue, currentValue) => {
    let isNullFilter = null;
    if (Array.isArray(filter[currentValue])) {
      const isnullCondition = filter[currentValue]?.find(
        (filter) => filter === 'filter_is_null'
      );
      if (isnullCondition) {
        isNullFilter = currentValue.includes('__exact')
          ? currentValue.replace('exact', 'isnull')
          : `${currentValue}__isnull`;
        const restFilterValue = filter[currentValue]?.filter(
          (filter) => filter !== 'filter_is_null'
        );
        return {
          ...previousValue,
          [isNullFilter]: true,
          [currentValue]: restFilterValue,
        };
      } else {
        if (mappingFilter && currentValue.includes(`attributes__`)) {
          const newValue = filter[currentValue].reduce((acc, current) => {
            const currentItemValue = JSON.stringify({
              [currentValue]: current,
            });
            return acc ? `${acc},${currentItemValue}` : currentItemValue;
          }, '');

          return {
            ...previousValue,
            input_kwargs__value_dict: previousValue?.input_kwargs__value_dict
              ? `${previousValue?.input_kwargs__value_dict},${newValue}`
              : newValue,
          };
        }
        return { ...previousValue, [currentValue]: filter[currentValue] };
      }
    } else {
      if (mappingFilter && currentValue.includes(`attributes__`)) {
        const newValue = JSON.stringify({
          [currentValue]: filter[currentValue],
        });

        return {
          ...previousValue,
          input_kwargs__value_dict: previousValue?.input_kwargs__value_dict
            ? `${previousValue?.input_kwargs__value_dict},${newValue}`
            : newValue,
        };
      }
      return { ...previousValue, [currentValue]: filter[currentValue] };
    }
  }, {});
  return newFilters;
};
/**
 * Calculates the measure text width in pixels for given text and font size
 *
 * @param {string} text
 * @param {number?} fontSize
 * @param {string?} fontFamily
 * @returns {number}
 */
export const measureTextWidth = (
  text,
  fontSize = 14,
  fontFamily = "'avenir next', sans-serif"
) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = `${fontSize}px ${fontFamily}`;
  return ctx.measureText(text).width;
};

/**
 * Separate the given string into words and return the words array
 *
 * Example:
 * ```ts
 * getStringAsChunk("HelloWorld12345", 5) => ["Hello", "World", "12345"]
 * ```
 *
 * @param {string} string
 * @param {number} length
 * @returns
 */
export const getStringAsChunk = (string, length) => {
  const size = Math.ceil(string.length / length);
  const result = [];
  for (let i = 0; i < size; i++) {
    result.push(string.substr(i * length, length));
  }
  return result;
};

export const downloadCSVTemplate = ({
  format = '',
  params = [],
  datatableURL = '',
  extraParams = {},
}) => {
  let filteredParams,
    queryOptions = {
      url: datatableURL,
      _fields: params,
      format: format,
      limit: 5,
      page: 1,
      ...filteredParams,
      ...extraParams,
    };
  const url = `/channel/${getContextChannelInfo().pk}${downloadURL}`;
  window.open(addQueryToURL(url, queryOptions), '_blank');
};

export const renderDownloadCSVTemplateButton = (
  csvDownloadAction = () => {}
) => (
  <Button className="download-btn" ghost onClick={csvDownloadAction}>
    {T('example.filter.template.download')}
  </Button>
);

export const stopPropagation = (event) => event.stopPropagation();

/**
 * Generate CSV file from given object
 *
 *  Example:
 * ```js
 * const data = [
 *  {
 *   id: '1'
 *   name: 'John'
 *  },
 *  {
 *   id: '2',
 *   name: 'Doe'
 *  },
 * ];
 * console.log(convertToCsv(data));
 * //id,name
 * //1,John
 * //2,Doe
 *
 * // Or
 *
 * console.log(convertToCsv(data, [{key: 'id', label: 'ID'}, {key: 'name', label: 'Name'}]));
 * //ID,Name
 * //1,John
 * //2,Doe
 * ```
 * @param {object} data
 * @returns
 */
export const convertToCsv = ({
  data,
  customHeader = [],
  separator = ',',
  newLine = '\r\n',
}) => {
  const csvRows = [];
  let headers = Object.keys(data[0]);
  if (customHeader.length) {
    const customHeaders = headers.map(
      (key) => customHeader.find((item) => item.key === key)?.label || key
    );
    csvRows.push(customHeaders.join(separator));
  } else {
    csvRows.push(headers.join(separator));
  }
  data.forEach((row) => {
    let csvRow = [];
    headers.forEach((header) => {
      csvRow.push(row[header]);
    });
    csvRows.push(csvRow.join(separator));
  });
  return csvRows.join(newLine);
};

/**
 * Download as CSV file from given object
 *
 * @param {object} object
 * @param {string} filename
 */
export const downloadAsCsv = (filename, csvParams) => {
  const csv = convertToCsv(csvParams);
  var BOM = '\uFEFF';
  var csvData = BOM + csv;
  var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, filename);
};

/**
 * @description Generates breadcrumb for given value in tree structured array.
 * @param {string} inputPk - Breadcrumb input node pk
 * @param {array} flatTree  - Tree Array
 * @returns {array} - An array contains breadcrumb and full path
 */
export const generateBreadCrumb = (inputPk, flatTree = []) => {
  const target = flatTree?.find((item) => item.pk == inputPk);
  const { path: targetPath } = target || {};

  const collectParents = (path, acc = []) => {
    if (path && path?.length > 4) {
      const parent = flatTree?.find((item) => item.path == path);
      acc.push(parent.name);
      path = path.slice(0, -4);

      return collectParents(path, acc);
    } else if (path?.length == 4) {
      return acc;
    }
  };

  let acc = collectParents(targetPath);
  acc = acc?.reverse();

  if (acc?.length > 0) {
    let firstEl = acc?.[0];
    let lastEl = acc?.[acc?.length - 1];
    return [`${firstEl} / ... / ${lastEl}`, acc];
  } else {
    return [target?.name, [target?.name]];
  }
};

/**
 * @description Generates nested children tree formed array.
 * @param {array} flatTree - Tree Array
 * @returns {array} - An array contains nested children tree
 */
export const generateTree = (arr = [], target = false) => {
  let map = {},
    node,
    res = [],
    i;

  arr = arr.map((item) => ({ key: item.pk, ...item }));

  for (i = 0; i < arr.length; i += 1) {
    map[arr[i].pk] = i;
    arr[i].children = [];
    if (target) {
      arr[i].title = arr[i].name;

      arr[i].icon = ({ expanded, isLeaf }) =>
        expanded ? (
          <Icon className="icon-folder_minus" />
        ) : (
          !isLeaf && <Icon className="icon-folder_plus" />
        );
    }
  }

  for (i = 0; i < arr.length; i += 1) {
    node = arr[i];
    if (node.depth !== 1) {
      let parentPath = node.path.slice(0, -4);
      parentPath = arr.filter((item) => item.path === parentPath);
      let parent = arr[map[parentPath[0].pk]];
      parent.children.push(node);
      node.isLeaf = false;

      if (node.numchild == 0) {
        delete node.children;
        node.isLeaf = true;
      } else {
        node.disabled = true;
      }
    } else {
      res.push(node);
      node.isLeaf = false;

      if (node.numchild == 0) {
        delete node.children;
        node.isLeaf = true;
      } else {
        node.disabled = true;
      }
    }
  }

  return res;
};
export const getOMSSpecialBreadcrumbContent = (desc, value) => {
  return (
    <div className="breadcrumb-content">
      {desc}
      <span className="breadcrumb-content-value">{value}</span>
    </div>
  );
};

export const generateHrefUrl = (url) => {
  const { lastChannelLang, lastPlatformLang } = navigator;

  return `/${lastPlatformLang}/${lastChannelLang}${url}`;
};

export const getConditionelInstoreURL = (url, instoreID) => {
  if (instoreID) {
    const splitURL = url.split('instore/');
    const restUrl = splitURL?.[1] ? `${splitURL[1]}` : '';
    return `instore/${instoreID}/${restUrl}`;
  }
  return url;
};

export const addPrefixToItemKeys = (items, prefix) => {
  return items.map((item) => {
    return {
      ...item,
      key: `${prefix}${item.key}`,
    };
  });
};

export const removeSearchParamsFromRelativePath = (
  relativePath,
  paramNames
) => {
  const urlObject = new URL(`http://${relativePath}`);

  paramNames.forEach((paramName) => urlObject.searchParams.delete(paramName));

  return decodeURI(urlObject.href).replace('http://', '');
};

export const commandsDescLabelOMS = (values) => {
  return actionCommands.find((item) => item.command === values)?.label || '';
};

/**
 * Execute a list of promises in parallel by n-concurrency limit and return a promise that resolves when all promises are resolved.
 *
 * Alternative for `Promise.all` with limited concurrency but **must be** created with currying function for each promise task to be executed manually by `taskPool`.
 *
 * Ex:
 * ```js
 * const result = await taskPool([
 *    () => new Promise(resolve => setTimeout(() => resolve(1), 1000)),
 *    () => new Promise(resolve => setTimeout(() => resolve(2), 1000)),
 *    () => new Promise(resolve => setTimeout(() => resolve(3), 1000))
 *    //...
 * ], 2) // third promise will execute when one or two promises are resolved
 * console.log(result) // [1, 2, 3, ...]
 * ```
 *
 * @param {(()=>Promise)[]} executableTasks - Promise task list to be executed in parallel by n-concurrency limit
 * @param {number} concurrency - Maximum parallel concurrency limit. Default is `20`.
 * @returns {Promise} - Promise that resolves when all promises are resolved
 */
export const taskPool = async (executableTasks, concurrency = 20) => {
  let index = 0;
  const results = [];

  // Run a pseudo-thread
  const execThread = async () => {
    while (index < executableTasks.length) {
      const curIndex = index++;
      // Use of `curIndex` is important because `index` may change after await is resolved
      results[curIndex] = await (await executableTasks[curIndex])();
    }
  };

  // Start threads
  const threads = [];
  for (let thread = 0; thread < concurrency; thread++) {
    threads.push(execThread());
  }
  await Promise.all(threads);
  return results;
};
