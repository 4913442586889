import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DatatableBox } from '@components/Datatable';
import Box from '@components/utility/box/index';
import Filter from '@components/Filter';
import FilterNoStorage from '@components/Filter/filterNoStorage';

import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import { getObjectFromString } from '@utils';

import qs from 'qs';

// ! Filtreler icerisinde bir "key (item)" multiple search isteniyorsa; "multipleKeySearch:true" olarak eklenmelidir.
class DatatableWithFilter extends Component {
  state = {
    activeColumnFilters: [],
    columns: [],
    filterObject: {},
  };

  removeDuplicatedDefaultURLParams = (filterObject) => {
    const cloneFilterObject = Object.assign({}, filterObject);

    let {
      datatableProps: { url = '' },
    } = this.props;

    let splitKey = '/?';

    if (url) {
      let splittedURLandParams = url?.split(splitKey);
      if (splittedURLandParams.length > 1) {
        let urlParams = qs.parse(splittedURLandParams[1]);
        Object.keys(cloneFilterObject).forEach((filterKey) => {
          if (Object.keys(urlParams).includes(filterKey)) {
            delete urlParams[filterKey];
          }
        });
        let paramsEncodedToURL = qs.stringify(urlParams);
        this.props.datatableProps.url =
          splittedURLandParams[0] + splitKey + paramsEncodedToURL;
      }
    }
  };

  componentDidMount() {
    const {
      optionsURL,
      httpMethod,
      columnsObjectKey,
      filterObjectKey,
    } = this.props;
    if (optionsURL)
      sendRequest({
        method: httpMethod,
        url: optionsURL,
        onSuccess: (result) => {
          this.setState({
            filterInputs: getObjectFromString(filterObjectKey, result),
            columns: getObjectFromString(columnsObjectKey, result),
          });
        },
      });
  }

  filterTable = (filterObject, e) => {
    this.setState({ filterObject });
  };

  onChangeFilter = (
    filterObject,
    formFilters,
    filterInputs = [],
    filterFields = []
  ) => {
    const {
      filterProps: { preKey = '', posKey = '' },
    } = this.props;

    this.removeDuplicatedDefaultURLParams(filterObject);

    const activeColumns = filterFields?.filter((filter) => filter?.active);

    const dynamicFilterInputKeys = [];

    filterInputs.map((item) => {
      if (!item?.isStatic) dynamicFilterInputKeys.push(item?.key);
    });

    const cloneFilterObject = Object.assign({}, filterObject);

    const getFilterObjectAndRename = (obj, arr) => {
      Object.keys(obj).forEach((item) => {
        if (arr.includes(item)) {
          if (preKey.split('').length || posKey.split('').length) {
            [preKey, posKey].filter(Boolean).some((key) => item.includes(key))
              ? delete obj[(preKey && preKey) + item + (posKey && posKey)]
              : delete Object.assign(obj, {
                  [preKey + item + posKey]: obj[item],
                })[item];
          }
        }
      });
    };
    getFilterObjectAndRename(cloneFilterObject, dynamicFilterInputKeys);

    this.setState(
      {
        filterObject: cloneFilterObject,
        cloneFilterObject,
        formFilters,
        activeColumnFilters: activeColumns,
      },
      () => {
        this.props?.selectedFilters &&
          this.props?.selectedFilters({ ...this.state.filterObject });
      }
    );
  };

  onChangeActiveFilters = (filters = []) => {
    this.setState({
      activeColumnFilters: filters.filter((filter) => filter.columnProps),
    });
  };

  getColumns = () => {
    let { datatableProps = {} } = this.props;
    const { columns: columnsFromState, activeColumnFilters = [] } = this.state;
    const columns = datatableProps.columns || columnsFromState;

    const leftColumns = columns.filter(
      (col) => col.fixed == 'left' || !col.fixed
    );
    const rightColumns = columns.filter((col) => col.fixed == 'right');

    return this.removeDuplicates([
      ...leftColumns,
      ...activeColumnFilters.map((filter) => ({ ...filter.columnProps })),
      ...rightColumns,
    ]);
  };

  getFilterType = () => {
    const { filterInputs } = this.state;
    const { filterProps, selectedDefaultFilters } = this.props;
    const ftProps = {
      ...filterProps,
      ...(filterInputs ? { filterInputs } : {}),
    };
    return filterProps.storage === false ? (
      <FilterNoStorage
        onSubmit={this.filterTable}
        onChange={this.onChangeFilter}
        onChangeActiveFilters={this.onChangeActiveFilters}
        selectedDefaultFilters={selectedDefaultFilters}
        inline
        {...ftProps}
      />
    ) : (
      <Filter
        onSubmit={this.filterTable}
        onChange={this.onChangeFilter}
        onChangeActiveFilters={this.onChangeActiveFilters}
        inline
        {...ftProps}
      />
    );
  };

  reload = () => {
    this.datatable.reload();
  };

  setRef = (datatable) => {
    this.datatable = datatable;
  };

  addRow = (...args) => {
    this.datatable.addRow(...args);
  };

  removeRow = (...args) => {
    this.datatable.removeRow(...args);
  };

  removeDuplicates = (columns) => {
    // ** Note: Dinamik filtreler ['attributes','foo'], seklinde geliyor.
    // ** Static filtreler tekrar tabloya eklenmemesi icin asagidaki kontrol eklendi.
    let uniqueCols = [];

    for (let loopCol of columns) {
      if (!loopCol?.isStatic) {
        uniqueCols.push(loopCol);
      }
    }
    return uniqueCols;
  };

  render() {
    const { filterObject, formFilters, filterInputs } = this.state;
    const { datatableProps = {}, filterProps } = this.props;
    const ftProps = {
      ...filterProps,
      ...(filterInputs ? { filterInputs } : {}),
    };
    const { filterFormVisible = true } = ftProps;
    const filterType = this.getFilterType();
    const dtProps = { ...datatableProps, columns: this.getColumns() };

    return (
      <>
        {filterFormVisible && (
          <Box className="filter-form-wrapper" data-tour="filter-form-wrapper">
            {filterType}
          </Box>
        )}
        <DatatableBox
          setRef={this.setRef}
          filter={filterObject}
          formFilters={formFilters}
          {...dtProps}
        />
      </>
    );
  }
}

DatatableWithFilter.defaultProps = {
  filterObjectKey: 'filters',
  columnsObjectKey: 'columns',
  httpMethod: httpMethods.OPTIONS,
};

DatatableWithFilter.propTypes = {
  optionsURL: PropTypes.string,
  filterObjectKey: PropTypes.string,
  columnsObjectKey: PropTypes.string,
  filterProps: PropTypes.shape({
    getFiltersURL: PropTypes.string,
    filterButtons: PropTypes.array,
    staticFilters: PropTypes.array,
    className: PropTypes.string,
    preKey: PropTypes.string,
    posKey: PropTypes.string,
    storage: PropTypes.bool,
    collapsible: PropTypes.bool,
    filterViaFile: PropTypes.bool,
    onChangeActiveFilters: PropTypes.func,
    filterFormVisible: PropTypes.bool,
    multipleKeySearch: PropTypes.bool,
    defaultFilters: PropTypes.object,
    columnDataKey: PropTypes.array,
  }),
  datatableProps: PropTypes.shape({
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    columns: PropTypes.array,
    rowKey: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    rowSelection: PropTypes.object,
    effaceable: PropTypes.bool,
    saveable: PropTypes.bool,
    size: PropTypes.string,
    expandedRowRender: PropTypes.func,
    expandRowByClick: PropTypes.bool,
    horizontalScroll: PropTypes.bool,
    verticalScroll: PropTypes.bool,
    bordered: PropTypes.bool,
    locale: PropTypes.object,
    onChangeDataSource: PropTypes.func,
    actionButtons: PropTypes.array,
    onRowClick: PropTypes.func,
    exportable: PropTypes.bool,
    subtitle: PropTypes.any,
    exportButtonClick: PropTypes.func,
    isAuthorized: PropTypes.bool,
    authActionName: PropTypes.string,
    wideColumns: PropTypes.bool,
  }),
};

export default DatatableWithFilter;
