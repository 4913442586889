import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';
import { dateFormatter } from '@utils';
import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { setActionElements } from '@common/index';
import Button from '@components/Button';
import { priceOptimizationPriceList } from '@constants/serviceUrls';
import { savePriceList } from '@resources/actions/priceOptimization';
import '../style.scss';

function OptimizationPriceList({ savePriceList }) {
  const [selectedRow, setSelectedRow] = useState(false);

  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() =>
          onNavigate('/price/price-list-optimization/price-detail-list/form')
        }
      >
        {T('add.new.price.list')}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'name__icontains',
      name: T('price.list'),
      pk: '1',
      active: true,
      fieldProps: {
        placeholder: T('price.list'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'code__icontains',
      name: T('price.list.code'),
      pk: '2',
      active: true,
      fieldProps: {
        placeholder: T('price.list.code'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'created_date__gte',
      name: T('first.creation.date'),
      pk: '3',
      active: true,
      fieldProps: {
        placeholder: T('first.creation.date'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'created_date__lte',
      name: T('last.creation.date'),
      pk: '4',
      active: true,
      fieldProps: {
        placeholder: T('last.creation.date'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'modified_date',
      name: T('last.update'),
      pk: '5',
      active: true,
      fieldProps: {
        placeholder: T('last.update'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'modified_date__gte',
      name: T('first.updated.date'),
      pk: '6',
      active: true,
      fieldProps: {
        placeholder: T('first.updated.date'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'modified_date__lte',
      name: T('last.updated.date'),
      pk: '7',
      active: true,
      fieldProps: {
        placeholder: T('last.updated.date'),
      },
    },
  ];

  const columns = [
    {
      key: 0,
      dataIndex: 'id',
      title: 'ID',
    },
    {
      key: 5,
      dataIndex: 'remote_id',
      title: 'remote id',
      visible: false,
      className: 'hide',
    },
    {
      key: 1,
      dataIndex: 'name',
      title: T('price.list'),
      render: (cellData, row) => (
        <a onClick={() => onPriceListClick(row)}>{cellData}</a>
      ),
      editable: true,
    },
    {
      key: 2,
      dataIndex: 'code',
      title: T('price.list.code'),
      editable: true,
    },
    {
      key: 3,
      dataIndex: 'created_date',
      title: T('created.date'),
      render: (cellData) => dateFormatter(cellData, true),
    },
    {
      key: 4,
      dataIndex: 'modified_date',
      title: T('update.date'),
      render: (cellData) => dateFormatter(cellData, true),
    },
  ];

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    setSelectedRow(row);
  };

  const onPriceListClick = (row) => {
    onNavigate(
      `/price/price-list-optimization/price-detail-list/${row.id}/${row.code}`
    );
    // history.push({
    //   pathname: `/price/price-list-optimization/price-detail-list/${row.id}`,
    //   code: row.code,
    // });
  };

  const onChangeRow = (row) => {
    const { id, name, code } = selectedRow;
    const paramsAttribute = {
      name,
      code,
    };

    savePriceList(id, paramsAttribute).then(() => {
      onNavigate(`/price/price-list-optimization/price-list`);
    });
  };

  return (
    <LayoutContentWrapper className="price-optimization-list">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          saveable: true,
          saveableFixed: 'right',
          rowKey: 'id',
          doubleClickable: true,
          exportable: false,
          columns: columns,
          subtitle: T('price.list'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(T('price.list')),
          url: priceOptimizationPriceList,
          onRowClick,
          onChangeRow,
        }}
      />
    </LayoutContentWrapper>
  );
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = {
  savePriceList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizationPriceList);
