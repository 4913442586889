import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { Upload } from 'antd';
import moment from 'moment';
import {
  saveScheduledProductPrice,
  saveCurrentScheduledProductPrice,
  getBulkProductPriceStatus,
} from '@resources/actions/priceOptimization';
import {
  priceOptimizationScheduledProductPrice,
  baseURLSet,
} from '@constants/serviceUrls';
import { setActionElements, getAPILanguage } from '@common';
import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { dateFormatter } from '@utils';
import { removeStorageItem } from '@common/storage';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import DatatableWithFilter from '@components/DatatableWithFilter';
import { inputTypes } from '@components/DynamicForm/constants';
import Button from '@components/Button';
import Block from '@components/Block';

import FileSaver from 'file-saver';
import axios from 'axios';
import { tokenHeaderKEY } from '@constants/';
import { store } from '@resources/store';

import '../style.scss';

const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
function OptimizationScheduledProductPrice({
  getBulkProductPriceStatus,
  saveScheduledProductPrice,
  saveCurrentScheduledProductPrice,
}) {
  const location = useLocation();
  const { search, param } = location;

  const sku = getParameterByName('product_price__sku', search || param);
  const priceCode = getParameterByName(
    'product_price__price_list__code',
    search || param
  );

  const priceListId = getParameterByName(
    'product_price__price_list__id',
    search || param
  );

  const [state, setState] = useState({
    loading: false,
    excelToggle: false,
    isDownloading: [false, false],
    sku,
    priceCode,
    filterCheck: false,
    productPriceUpdateDisabled: false,
    productPriceUpdateStart: undefined,
  });

  const actionElement = (
    <Button
      type="secondary"
      icon="icon-arti"
      onClick={() => {
        setState((prevState) => ({
          ...prevState,
          excelToggle: !prevState.excelToggle,
        }));
      }}
    >
      {T('update.price.list')}
    </Button>
  );

  useEffect(() => {
    state.sku === undefined &&
      onNavigate('/price/price-list-optimization/price-list/');
    setActionElements([actionElement]);

    // We clean the storage for the filter to work correctly.
    removeStorageItem(
      '/price/price-list-optimization/price-detail-list/scheduledproductprice/'
    );
    removeStorageItem(
      '/price/price-list-optimization/price-detail-list/scheduledproductprice/?pures'
    );
    setState((prevState) => ({
      ...prevState,
      filterCheck: true,
      selectedRow: null,
    }));

    return () => {
      setActionElements([]);
    };
  }, [search, param, state.sku, state.priceCode]);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'sku__icontains',
      name: T('sku'),
      pk: 1,
      active: true,
      fieldProps: {
        placeholder: T('sku'),
      },
      default_value: state.sku,
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'price',
      name: T('selling.price'),
      pk: 2,
      active: true,
      fieldProps: {
        placeholder: T('selling.price'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'period__startswith__gte',
      name: T('current.price.start'),
      pk: 3,
      active: true,
      fieldProps: {
        placeholder: T('current.price.start'),
      },
    },
    {
      data_type: { value: inputTypes.datepicker.alias },
      key: 'period__endswith__lte',
      name: T('current.price.end'),
      pk: 4,
      active: true,
      fieldProps: {
        placeholder: T('current.price.end'),
      },
    },
  ];

  const columns = [
    {
      key: 0,
      dataIndex: 'id',
      title: 'id',
      visible: false,
      className: 'hide',
      editable: true,
    },
    {
      key: 1,
      dataIndex: 'price_list',
      title: T('current.sale.price'),
      visible: false,
      className: 'hide',
    },
    {
      key: 2,
      dataIndex: 'product_price__sku',
      title: T('sku'),
    },
    {
      key: 3,
      dataIndex: 'product_price__price_list__code',
      title: T('price.list.code'),
    },
    {
      key: 4,
      dataIndex: 'price',
      title: 'Zamanlik satis fiyati',
      editable: true,
    },
    {
      key: 5,
      dataIndex: 'period.lower',
      title: T('current.price.start'),
      inputType: 'timezoneDate',
      render: (cellData) => (cellData ? dateFormatter(cellData) : cellData),
      editable: true,
    },
    {
      key: 6,
      dataIndex: 'period.upper',
      title: T('current.price.end'),
      render: (cellData) => (cellData ? dateFormatter(cellData) : cellData),
      inputType: 'timezoneDate',
      editable: true,
    },
    {
      key: 7,
      dataIndex: 'product_price',
      title: 'product price',
      visible: false,
      className: 'hide',
      editable: true,
    },
  ];

  const onRowClick = (row) => {
    setState((prevState) => ({ ...prevState, selectedRow: row }));
  };

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  let bulkProductsPriceInterval;
  const priceListUpload = (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append('filename', file);

    // Append default timezone and price list id to form body.
    const contextData = {
      timezone: 'Europe/Istanbul',
      price_list_id: priceListId,
    };
    bodyFormData.append('context', JSON.stringify(contextData));

    saveCurrentScheduledProductPrice(bodyFormData).then((res) => {
      const { tracking_slug } = res;
      if (tracking_slug) {
        bulkProductsPriceInterval = setInterval(
          () => checkProductPriceCacheKey(tracking_slug),
          2500
        );
        setState((prevState) => ({
          ...prevState,
          productPriceUpdateDisabled: true,
          productPriceUpdateStart: true,
        }));
      }
    });

    return false;
  };

  const checkProductPriceCacheKey = (tracking_slug) => {
    getBulkProductPriceStatus(tracking_slug)
      .then((result) => {
        if (
          result &&
          result.status === 'succeeded' &&
          bulkProductsPriceInterval
        ) {
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
          message.success('İşlem tamamlanmıştır.');
        } else if (
          result &&
          result.status === 'failed' &&
          bulkProductsPriceInterval
        ) {
          message.error('İşlem Başarısız');
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
        }
      })
      .catch(() => {
        if (bulkProductsPriceInterval) {
          clearInterval(bulkProductsPriceInterval);
          setState((prevState) => ({
            ...prevState,
            productPriceUpdateDisabled: false,
            productPriceUpdateStart: undefined,
          }));
        }
      });
  };

  const onChangeRow = (row) => {
    const { id, period, price, product_price } = state.selectedRow;

    const lower = period.lower?._d
      ? moment(period.lower._d).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSS[Z]')
      : period.lower;
    const upper = period.upper?._d
      ? moment(period.upper._d).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSS[Z]')
      : period.upper;

    const paramsAttribute = {
      period: {
        lower,
        upper,
      },
      price,
      product_price,
    };
    saveScheduledProductPrice(id, paramsAttribute);
  };

  const onDownloadClick = (e, url, index) => {
    const newLoadings = [...state.isDownloading];
    const token = store.getState().authReducer.token;
    newLoadings[index] = true;
    setState((prevState) => ({
      ...prevState,
      isDownloading: newLoadings,
    }));
    e.stopPropagation();
    axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Accept-Language': getAPILanguage(),
          'Content-Type': 'application/text',
          Authorization: `${tokenHeaderKEY} ${token}`,
        },
      })
      .then((response) => {
        const respHeaders = response.headers;
        const blob = new Blob([response.data], {
          type: respHeaders['content-type'],
        });
        const filename = respHeaders['content-disposition']
          .split('=')
          .slice(1)[0];
        FileSaver.saveAs(blob, filename);
        setState((prevState) => ({
          ...prevState,
          isDownloading: [false, false],
        }));
      });
  };

  return (
    <LayoutContentWrapper className="card-setting-detail price-optimization-scheduled-product">
      {state.excelToggle && (
        <Block className="excel-update-panel">
          <Block className="col col-start">
            <Block className="excel-update-panel__title">
              {T('update.bulk.price.list')}
            </Block>
          </Block>
          <Block className="col col-end excel-update-panel__link-group">
            <Button
              type="primary"
              className="preproduct-button-action"
              icon="icon-doc"
              loading={state.isDownloading[0]}
              onClick={(e) =>
                onDownloadClick(
                  e,
                  baseURLSet.default +
                    priceOptimizationScheduledProductPrice +
                    `excel_export_with_import_template/?product_price__price_list__code=${state.priceCode}`,
                  0
                )
              }
            >
              {T('template.price.download')}
            </Button>

            <Button
              type="primary"
              className="preproduct-button-action"
              icon="icon-doc"
              loading={state.isDownloading[1]}
              onClick={(e) =>
                onDownloadClick(
                  e,
                  baseURLSet.default +
                    priceOptimizationScheduledProductPrice +
                    `excel_export/?product_price__price_list__code=${state.priceCode}`,
                  1
                )
              }
            >
              {T('current.price.download')}
            </Button>
          </Block>
          <Block className="col w-100">
            <p className="w-100">{T('page.price.scheduled.upload.desc')}</p>
            <Upload
              className=""
              fileList={[]}
              beforeUpload={priceListUpload}
              multiple={false}
              accept=".xls,.xlsx,.csv"
              disabled={state.productPriceUpdateDisabled}
            >
              <Button
                type="secondary"
                loading={state.productPriceUpdateDisabled}
              >
                {T('current.price.upload')}
              </Button>
            </Upload>
          </Block>
        </Block>
      )}
      {state.filterCheck && (
        <DatatableWithFilter
          filterProps={{ staticFilters: filterInputs }}
          datatableProps={{
            saveable: true,
            saveableFixed: 'right',
            rowKey: 'id',
            columns: columns,
            rowSelection: false,
            onRowClick,
            subtitle: T('price.list'),
            locale: { emptyText: T('result.not.found') },
            paginationText: T('show.variable').format(T('price.list')),
            onChangeRow,
            url: priceOptimizationScheduledProductPrice + (param || search),
            exportable: false,
            doubleClickable: false,
            loading: state.productPriceUpdateStart
              ? state.productPriceUpdateDisabled
              : undefined,
          }}
        />
      )}
    </LayoutContentWrapper>
  );
}

const mapStateToProps = () => {};

const mapDispatchToProps = {
  saveScheduledProductPrice,
  saveCurrentScheduledProductPrice,
  getBulkProductPriceStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizationScheduledProductPrice);
