import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Landing from './Landing';
import OptimizationPriceList from './Lists/OptimizationPriceList';
import OptimizationProductPrice from './Lists/OptimizationProductPrice';
import OptimizationScheduledProductPrice from './Lists/OptimizationScheduledProductPrice';
import PriceListOptimizationForm from './PriceListOptimizationForm';

function PriceListOptimization() {
  return (
    <Switch>
      <Route exact path="/price/price-list-optimization/" component={Landing} />
      <Route
        path="/price/price-list-optimization/price-detail-list/form"
        component={PriceListOptimizationForm}
      />
      <Route path="/price/price-list-optimization/price-list/" component={OptimizationPriceList} />
      <Route
        path="/price/price-list-optimization/price-detail-list/scheduledproductprice/:query?"
        component={OptimizationScheduledProductPrice}
      />
      <Route
        path="/price/price-list-optimization/price-detail-list/:id/:code?"
        component={OptimizationProductPrice}
      />
    </Switch>
  );
}
export default PriceListOptimization;
