import { httpMethods } from '@constants/commontypes';
import { sendRequest } from '@common/network';
import { addQueryToURL } from '@utils';
import { bulkDeleteProductPrices, priceOptimizationProductPrice } from '@constants/serviceUrls';

export const deleteBulkProductPrices = ({ queryParams }) => () =>
  sendRequest({
    url: addQueryToURL(bulkDeleteProductPrices, queryParams),
    method: httpMethods.POST,
  });

export const addOrDeletePricesBulkStatus = (cache_key, addAll = null) => () =>
  sendRequest({
    url: addAll
      ? `${priceOptimizationProductPrice}${cache_key}/bulk_create_status/`
      : `${priceOptimizationProductPrice}${cache_key}/bulk_delete_status/`,
  });
