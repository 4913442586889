import React, { lazy, Suspense, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { runInitialJobs } from '../../configs/initial';
import {
  checkAuthorization,
  setTokenFromStorage,
  getPermissions,
} from '@resources/actions/auth';
import { navigator, history } from '@common/navigation';
import Spin from '@components/UIElements/spin';
import Block from '@components/Block';
import route from '@components/Route';
import Masterpage from '../MasterPage';
import { addToRouteStack } from '@resources/actions/app';
import { redirectToSignInPage } from '@utils/';
const SignIn = lazy(() => import('../Authentication/signin'));
const SignUp = lazy(() => import('../Authentication/signup'));
const Error404 = lazy(() => import('../Error/404/404'));
const Error500 = lazy(() => import('../Error/500/500'));

const guestPages = ['/price/signin', '/'];

const AuthorizedMasterpage = route({
  component: Masterpage,
  actionName: 'dashboard',
  unAuthorizedProps: { disabled: true },
  unAuthorizedURL: '/price/signin',
});

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isAuthChecked: false };
    this.getPermissions = this.getPermissions.bind(this);
    runInitialJobs();
  }

  componentDidMount() {
    this.checkAuth();
    this.listenHistory();
  }

  componentWillUnmount() {
    // component kaldırıldığında dinlemeyi durdur
    if (this.historyListener) {
      this.historyListener();
    }
  }

  checkAuth = () => {
    setTokenFromStorage()
      .then(() =>
        this.requestAuth().then(() => this.setState({ isAuthChecked: true }))
      )
      .catch(this.handleRedirect);
  };

  listenHistory = () => {
    this.props.addToRouteStack({ action: 'PUSH', pathname: history.location });
    this.historyListener = history.listen((location, action) => {
      const { pathname, state } = location;
      this.props.addToRouteStack({ action, pathname, state });
    });
  };

  requestAuth = () => {
    return this.props.checkAuthorization().catch(this.handleRedirect);
  };

  handleRedirect = () => {
    redirectToSignInPage(() => {
      this.setState({ isAuthChecked: true });
    });
  };

  getPermissions() {
    const { getPermissions, userData, location } = this.props;
    getPermissions(userData.pk)
      .then(() => {
        let isInGuestPages = false;
        for (let index = 0; index < guestPages.length; index++) {
          isInGuestPages = location.pathname === guestPages[index];
          if (isInGuestPages) break;
        }
        if (isInGuestPages) navigator.push('/');
        this.setState({ isAuthChecked: true });
      })
      .catch(() => this.setState({ isAuthChecked: true }));
  }

  render() {
    const loader = (
      <Spin>
        <Block className="fullContain" />
      </Spin>
    );
    if (!this.state.isAuthChecked) return loader;

    return (
      <Suspense fallback={() => loader}>
        <Switch>
          <Route exact path="/price/signin" component={SignIn} />
          <Route exact path="/price/404" component={Error404} />
          <Route exact path="/price/500" component={Error500} />
          <Route exact path="/price/signup" component={SignUp} />
          {/*
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/404" component={Error404} />
          <Route exact path="/500" component={Error500} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route path="/auth/resetPassword/:uid/:token" component={ResetPassword} /> */}
          <Route path="/" component={Masterpage} />
        </Switch>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    isLoggedIn: state.authReducer.isLoggedIn,
    isAuthChecked: state.authReducer.isAuthChecked,
    userData: state.authReducer.userData,
  };
}

export default connect(mapStateToProps, {
  setTokenFromStorage,
  checkAuthorization,
  getPermissions,
  addToRouteStack,
})(App);
