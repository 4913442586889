import React from 'react';

import { T } from '@utils/languageProvider';

import NavigationCardList from '@components/NavigationCard/list';
import LayoutContentWrapper from '@components/utility/layoutWrapper';

function PriceListOptimizationLanding() {
  const getNavigationList = () => {
    return [
      {
        link: '/price/price-list-optimization/price-list',
        title: T('price.list.optimization'),
        text: T('price.list.desc'),
        color: '#ffa154',
        intrinsic: 'icon-pos-terminal',
      },
    ];
  };

  return (
    <LayoutContentWrapper className="module-landing">
      <NavigationCardList navigationList={getNavigationList()} />
    </LayoutContentWrapper>
  );
}

export default PriceListOptimizationLanding;
